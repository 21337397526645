import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import _get from "lodash/get";

import Theme from "../../styles/theme";

import RegIcon from "../../images/VReg.svg";
import LockIcon from "../../images/LockIcon.svg";

import ToggleMenuIcon from "../../images/nav/icons_open_menu.svg";
import { ReactComponent as ExpandIcon } from "../../images/nav/icons_expand_more.svg";
import { ReactComponent as VPortIcon } from "../../images/nav/icon_vport.svg";
import { ReactComponent as VSourceIcon } from "../../images/nav/icons_vsource.svg";
import { ReactComponent as VlinkIcon } from "../../images/nav/icons_vlink.svg";
import { ReactComponent as VKeyIcon } from "../../images/nav/icons_vkey.svg";

import { useCheckAuth } from "./useCheckAuth";

const ToggleSideBarButton = styled.div`
    background: url(${ToggleMenuIcon}) center center no-repeat;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: 8px;
    transition: transform 0.25s ease-in-out;
    cursor: pointer;
`;

const Sidebar = styled.nav`
    background-color: ${Theme.sideNavBackground};
    display: flex;
    justify-content: start;
    flex-direction: column;
    border-right: 1px solid ${Theme.newGray};
    transition: width 0.25s ease-in-out;
    width: 56px;
    margin: 0;
    grid-area: sidenav;
    overflow: hidden;
    position: relative;

    &.open {
        width: 200px;

        ${ToggleSideBarButton} {
            transform: rotate(180deg);
        }
    }
`;

const ItemsWrapper = styled.div`
    overflow-y: auto;
`;

const NavItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 0;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
`;

const ServiceIcon = styled.div`
    display: flex;
    margin: 0 16px 0 13px;
`;

const Label = styled.span`
    align-self: center;
    font-size: 16px;
    font-weight: 600;
    color: #a5a4a1;
`;

const DropdownMenuItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    transition: max-height 0.25s ease;
    overflow: hidden;
    max-height: 0;
`;

const InnerNavItemContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;

    border-left: 3px solid transparent;

    &:hover {
        ${Label} {
            color: #242424;
        }

        ${ServiceIcon} {
            svg {
                fill: #242424;
            }
        }
    }

    &.active {
        border-left: 3px solid #ffc309;

        ${ServiceIcon} {
            svg {
                fill: #ffc309;
            }
        }

        ${Label} {
            color: #242424;
        }
    }

    &.open + ${DropdownMenuItemsWrapper} {
        max-height: 100vh;
    }
`;

const LockIconImg = styled.img`
    position: absolute;
    top: -8px;
    right: -16px;
    height: 1.5rem;
    /* filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(297deg) brightness(96%) contrast(104%); */
`;

const LabelFirstDifferent = styled.span`
    margin-left: 20px;
    align-self: center;
    font-size: 1.2rem;
    font-weight: 600;

    &::first-letter {
        color: ${Theme.yellow1};
        font-size: 1.325rem;
    }
`;

export const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

export const ExpandIndicator = styled.div`
    svg {
        fill: #a5a4a1;
        transition: fill 0.25s ease-in-out, transform 0.25s ease-in-out;
    }

    display: flex;
    margin-left: auto;
    margin-right: 8px;

    &.open {
        svg {
            fill: #242424;
            transform: rotate(180deg);
            transform-origin: center center;
        }
    }
`;

export const DropdownItem = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #a5a4a1;
    height: 32px;
    display: flex;
    padding-left: 58px;
    margin: 4px 0;
    align-items: center;
    cursor: pointer;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 4px;
    transition: color 0.125s ease-in-out;

    &:hover {
        color: #242424;
    }

    &.active {
        color: #242424;
    }
`;

export const DropdownMenu = ({ children }) => {
    return <DropdownMenuItemsWrapper>{children}</DropdownMenuItemsWrapper>;
};

export const NavItem = ({ label, icon, locked, active, submenu, isOpen, setOpen, content, link, noCursor }) => {
    const history = useHistory();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const setDropDownToggle = () => setDropdownOpen(!isDropdownOpen);
    const [willOpen, setWillOpen] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setDropdownOpen(false);
        }

        if (isOpen && active) {
            setDropdownOpen(true);
        }

        if (isOpen && willOpen) {
            setDropdownOpen(true);
            setWillOpen(false);
        }
    }, [isOpen, active, willOpen]);

    return (
        <NavItemWrapper
            style={{
                opacity: locked ? "0.4" : null,
                cursor: (link || submenu) && !locked && !noCursor ? "pointer" : null,
            }}
            onClick={link && !locked ? () => history.push(link) : null}
        >
            <InnerNavItemContainer
                className={`${active && "active"} ${isDropdownOpen && "open"}`}
                onClick={() => {
                    if (!isOpen) {
                        console.log("Opening menu from closed");
                        submenu && setWillOpen(true);
                        submenu && setOpen && setOpen(true);
                    } else {
                        setDropDownToggle();
                    }
                }}
                title={content}
            >
                <ServiceIcon style={{ position: "relative" }}>
                    {icon}
                    {locked && <LockIconImg src={LockIcon} />}
                </ServiceIcon>
                <Label>{label}</Label>
                {submenu && isOpen && !locked && (
                    <ExpandIndicator className={isDropdownOpen ? "open" : ""}>
                        <ExpandIcon />
                    </ExpandIndicator>
                )}
            </InnerNavItemContainer>
            {submenu && <>{submenu}</>}
        </NavItemWrapper>
    );
};

const Background = styled.div`
    display: block;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 70px;
    bottom: 0;
    left: 0;
    width: 200px;
    overflow: hidden;
    z-index: 9999;
`;

export const Sidenav = ({ children }) => {
    const [open, setOpen] = useState(true);
    const [openAfterTransition, setOpenAfterTransition] = useState(true);
    const sidenavRef = useRef(null);
    const [showModalBackground, setShowModalBackground] = useState(false);

    const handleModal = useCallback((e) => {
        setShowModalBackground(e.type === "showModalBackground");
    }, []);

    const handleTransitionStart = useCallback((e) => {
        e.stopPropagation();

        const _open = sidenavRef.current?.classList.contains("open");

        if (e.target === sidenavRef.current && _open) {
            setOpenAfterTransition(true);
        }
    }, []);

    const handleTransitionEnd = useCallback((e) => {
        e.stopPropagation();

        const _open = sidenavRef.current?.classList.contains("open");

        if (e.target === sidenavRef.current) {
            setOpenAfterTransition(_open);
        }
    }, []);

    useEffect(() => {
        sidenavRef.current.addEventListener("transitionstart", handleTransitionStart);
        sidenavRef.current.addEventListener("transitionend", handleTransitionEnd);
        window.addEventListener("showModalBackground", handleModal);
        window.addEventListener("hideModalBackground", handleModal);

        return () => {
            sidenavRef.current.removeEventListener("transitionstart", handleTransitionStart);
            sidenavRef.current.removeEventListener("transitionend", handleTransitionEnd);
            window.removeEventListener("showModalBackground", handleModal);
            window.removeEventListener("hideModalBackground", handleModal);
        };
    }, []);

    return (
        <Sidebar ref={sidenavRef} className={open ? "open" : ""}>
            {showModalBackground && <Background style={{ width: open ? "200px" : "56px" }} />}
            <ToggleSideBarButton onClick={() => setOpen((old) => !old)} />
            <ItemsWrapper>{children(openAfterTransition, setOpen)}</ItemsWrapper>
        </Sidebar>
    );
};

export const SideNavigation = () => {
    const history = useHistory();
    const route = useRouteMatch();
    const pathname = _get(history, "location.pathname");

    const isVkeyActive = useMemo(() => {
        return !pathname.startsWith("/vkey2") && pathname.startsWith("/vkey");
    }, [pathname]);

    const isActiveLink = useCallback(
        (menuItemLink) => {
            return pathname.includes(menuItemLink);
        },
        [pathname]
    );

    const nav = useCallback(
        (path) => {
            if (pathname.startsWith("/vport")) {
                const vportNavigationEvent = new CustomEvent("vport-navigation", {
                    detail: { path },
                });

                window.dispatchEvent(vportNavigationEvent);
                history.push(path);
            }

            if (pathname.startsWith("/vkey2")) {
                const vkeyNavigationEvent = new CustomEvent("vkey-navigation", {
                    detail: { path },
                });

                window.dispatchEvent(vkeyNavigationEvent);
            }

            history.push(path);
        },
        [pathname]
    );

    const {
        isVisibleVSource,
        isVisibleVKey,
        isVisibleVPort,
        isVisibleVLink,
        isVisibleReg,
        isUnlockedVSource,
        isUnlockedVKey,
        isUnlockedVPort,
        isUnlockedVLink,
        isVKeyAnalyst,
        isVKey2User,
    } = useCheckAuth();

    // Hide the sidenavigation on the client dashboard
    if (pathname === "/home") {
        return null;
    }

    return (
        <Sidenav>
            {(openHelper, setOpen) => (
                <>
                    {isVisibleVPort && (
                        <NavItem
                            icon={<VPortIcon />}
                            label="VPort"
                            isService
                            isOpen={openHelper}
                            setOpen={setOpen}
                            locked={!isUnlockedVPort}
                            active={isActiveLink("/vport")}
                            content="Integrated Vendor Management Portal"
                            submenu={
                                <DropdownMenu>
                                    <DropdownItem
                                        className={"/vport/home" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/home")}
                                    >
                                        Home
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vport/vendors" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/vendors")}
                                    >
                                        Vendors
                                    </DropdownItem>
                                    {/*
                                    <DropdownItem
                                        className={"/vport/products" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/products")}
                                    >
                                        Products
                                    </DropdownItem>
                                    */}
                                    <DropdownItem
                                        className={"/vport/financials" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/financials")}
                                    >
                                        Financials
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vport/calendar" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/calendar")}
                                    >
                                        Calendar
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vport/vendors-notes" === pathname ? "active" : ""}
                                        onClick={() => nav("/vport/vendors-notes")}
                                    >
                                        Notes
                                    </DropdownItem>
                                </DropdownMenu>
                            }
                        />
                    )}
                    {isVisibleVSource && (
                        <NavItem
                            icon={<VSourceIcon />}
                            label="VSource"
                            isService
                            isOpen={openHelper}
                            setOpen={setOpen}
                            locked={!isUnlockedVSource}
                            link="/vsource/vendors"
                            active={isActiveLink("/vsource")}
                            content="Digital Vendor Services Directory"
                        />
                    )}
                    {isVisibleVLink && (
                        <NavItem
                            icon={<VlinkIcon />}
                            label="VLink"
                            isService
                            isOpen={openHelper}
                            setOpen={setOpen}
                            locked={!isUnlockedVLink}
                            active={isActiveLink("/vlink")}
                            link="/vlink/dashboard"
                            content="Market Data Customer Connection"
                        />
                    )}
                    {!isVKey2User && isVisibleVKey && (
                        <NavItem
                            icon={<VKeyIcon />}
                            label="VKey"
                            isService
                            isOpen={openHelper}
                            setOpen={setOpen}
                            locked={!isUnlockedVKey}
                            active={isVkeyActive}
                            content="Digitizing Market Data Contracts"
                            submenu={
                                <DropdownMenu>
                                    {isVKeyAnalyst && (
                                        <DropdownItem
                                            className={"/vkey/agreements" === pathname ? "active" : ""}
                                            onClick={() => history.push("/vkey/agreements")}
                                        >
                                            Agreements
                                        </DropdownItem>
                                    )}
                                    <DropdownItem
                                        className={"/vkey/vault" === pathname ? "active" : ""}
                                        onClick={() => history.push("/vkey/vault")}
                                    >
                                        Secure Vault
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vkey/summary" === pathname ? "active" : ""}
                                        onClick={() => history.push("/vkey/summary")}
                                    >
                                        Summary
                                    </DropdownItem>
                                </DropdownMenu>
                            }
                        />
                    )}
                    {isVKey2User && (
                        <NavItem
                            icon={<VKeyIcon />}
                            label="VKey"
                            isService
                            isOpen={openHelper}
                            setOpen={setOpen}
                            active={isActiveLink("/vkey2")}
                            content="Next generation VKey"
                            submenu={
                                <DropdownMenu>
                                    <DropdownItem
                                        className={"/vkey2/secure-vault" === pathname ? "active" : ""}
                                        onClick={() => nav("/vkey2/secure-vault")}
                                    >
                                        Secure Vault
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vkey2/documents" === pathname ? "active" : ""}
                                        onClick={() => nav("/vkey2/documents")}
                                    >
                                        Documents
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vkey2/document-family-groups" === pathname ? "active" : ""}
                                        onClick={() => nav("/vkey2/document-family-groups")}
                                    >
                                        DFGs
                                    </DropdownItem>
                                    <DropdownItem
                                        className={"/vkey2/purchases" === pathname ? "active" : ""}
                                        onClick={() => nav("/vkey2/purchases")}
                                    >
                                        Purchases
                                    </DropdownItem>
                                </DropdownMenu>
                            }
                        />
                    )}
                </>
            )}
        </Sidenav>
    );
};
