import React, { useCallback, useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, useRouteMatch } from "react-router-dom";

const Iframe = styled.iframe`
    width: 100%;
`;

const Background = styled.div`
    display: block;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    z-index: 9999;
`;

// VPort is hosted on the VKey2 service
const VKEY2_URL = process?.env?.REACT_APP_VKEY2_URL || "http://localhost:3002";

const Settings = () => {
    const iframe = useRef();
    const history = useHistory();
    const route = useRouteMatch();
    const [pathToNavigate, setPathToNavigate] = useState();
    const [tokenRequested, setTokenRequested] = useState(false);
    const [showModalBackground, setShowModalBackground] = useState(false);

    const { getCurrentJWT } = useContext(AuthContext);

    useEffect(() => {
        const proxyEvent = new CustomEvent(showModalBackground ? "showModalBackground" : "hideModalBackground");

        window.dispatchEvent(proxyEvent);
    }, [showModalBackground]);

    const sendToken = useCallback(() => {
        getCurrentJWT().then((t) => {
            if (iframe.current?.contentWindow) {
                iframe.current.contentWindow.postMessage(
                    {
                        token: `Bearer ${t}`,
                    },
                    "*",
                );
            }
        });
    });

    const sendNavigation = useCallback((navigation) => {
        if (iframe.current?.contentWindow) {
            iframe.current.contentWindow.postMessage(
                {
                    navigation,
                },
                "*",
            );
        }
    });

    useEffect(() => {
        if (pathToNavigate && tokenRequested) {
            sendNavigation(pathToNavigate);
        }
    }, [pathToNavigate, tokenRequested]);

    const handleMessage = useCallback((event) => {
        const microFeUrl = new URL(`${VKEY2_URL}/settings`);
        const originUrl = new URL(event.origin);

        if (event.data) {
            try {
                const eventData = JSON.parse(event.data);

                if (eventData?.type && originUrl.origin === microFeUrl.origin) {
                    switch (eventData.type) {
                        case "tokenRequest": {
                            setTokenRequested(true);
                            sendToken();
                            break;
                        }
                        case "navigation": {
                            const path = eventData.path === "/" ? "" : eventData.path;
                            history.replace(`${route.path}/${path}`);
                            break;
                        }
                        case "modal": {
                            setShowModalBackground(eventData.toggle);
                            break;
                        }
                        default:
                            break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, []);

    const handleNewToken = useCallback((e) => {
        const { token } = e.detail;

        if (iframe.current?.contentWindow) {
            iframe.current.contentWindow.postMessage({
                token: `Bearer ${token}`,
            }, "*");
        }
    }, []);

    const handleNavigation = useCallback((e) => {
        const { path } = e.detail;
        sendNavigation(path.replace(route.path, ""));
    }, []);

    useEffect(() => {
        setPathToNavigate(history.location.pathname.replace(route.path, ""));
        window.addEventListener("message", handleMessage);
        window.addEventListener("new-token", handleNewToken);
        window.addEventListener("settings-navigation", handleNavigation);

        return () => {
            window.removeEventListener("message", handleMessage);
            window.removeEventListener("new-token", handleNewToken);
            window.removeEventListener("settings-navigation", handleNavigation);
        };
    }, []);

    if (!process?.env?.REACT_APP_VKEY2_URL) {
        return <span>No URL defined</span>;
    }

    return <><Iframe ref={iframe} src={`${VKEY2_URL}?settings`} />{showModalBackground && <Background />}</>;
};

export default Settings;
